<template>
    <div class="report-container" v-if="data != null">
        <div class="report-section" v-for="(item, index) in (data?.sleepReport ?? [])" :key="item.id">
            <span class="report-section-title">{{ index + 1 }}.睡眠报告: </span>
            <span class="report-section-content">
                {{ reportParams(item) }}
            </span>
            <span style="display:inline-flex;">
                <div class="link" v-if="item?.reportId != null" @click="onClickReportLink(item)">
                    <img class="link-icon" src="@imgs/user-main-baseline-link.png" alt="">
                    睡眠报告链接
                </div>
            </span>
            <button style="display:inline-flex;" v-if="isEdit"
                @click.stop="onClickDelete(item?.reportId && item?.reportId != '' ? 'sleep-database' : 'sleep-manual', item, index)">
                <img class="delete-icon" src="@imgs/user-report-delete.png" alt="">
            </button>

            <span style="display: inline-block; width: 52px; height: 35px; background-color: transparent;"
                v-if="isEdit"></span>

            <button class="edit" style="float: right; position: absolute; right: 0; bottom: 7px;" v-if="isEdit"
                @click.stop="onClickEdit(item?.reportId && item?.reportId != '' ? 'sleep-database' : 'sleep-manual', item, index)">
                编辑
                <img class="edit-icon" src="@imgs/user-main-baseline-arrow.png" alt="">
            </button>
        </div>

        <div class="report-section flex-wrap" v-for="(item, index) in (data?.sleepReportFile ?? [])" :key="item.categoryId">
            <span class="report-section-title">{{ (data?.sleepReport?.length ?? 0) + index + 1 }}.{{ item.categoryName }}:
            </span>
            <div v-for="(file, fileIndex) in item?.file" class="link margin-left-10"
                @click="onClickFileLink(file, fileIndex, item?.file)">
                <img class="link-icon" src="@imgs/user-main-baseline-link.png" alt="">
                {{ file.name + '.' + getPathExtension(file.url) }}

                <button style="display:inline-flex;" v-if="isEdit"
                    @click.stop="onClickDelete('sleep-file', item, fileIndex)">
                    <img class="delete-icon" src="@imgs/user-report-delete.png" alt="">
                </button>
            </div>
            <button class="edit" v-if="isEdit" @click.stop="onClickEdit('sleep-file', item, index)">
                编辑
                <img class="edit-icon" src="@imgs/user-main-baseline-arrow.png" alt="">
            </button>
        </div>

        <div class="report-section flex-wrap" v-for="(item, index) in (data?.inspectionReportFile ?? [])" :key="item.categoryId">
            <span class="report-section-title">{{ (data?.sleepReport?.length ?? 0) + (data?.sleepReportFile ??
                []).length + index + 1 }}.{{ item.categoryName }}: </span>
            <div v-for="(file, fileIndex) in item?.file" class="link margin-left-10"
                @click="onClickFileLink(file, fileIndex, item?.file)">
                <img class="link-icon" src="@imgs/user-main-baseline-link.png" alt="">
                {{ file.name + '.' + getPathExtension(file.url) }}

                <button style="display:inline-flex;" v-if="isEdit"
                    @click.stop="onClickDelete('other-file', item, fileIndex)">
                    <img class="delete-icon" src="@imgs/user-report-delete.png" alt="">
                </button>
            </div>
            <button class="edit" v-if="isEdit" @click.stop="onClickEdit('other-file', item, index)">
                编辑
                <img class="edit-icon" src="@imgs/user-main-baseline-arrow.png" alt="">
            </button>
        </div>
        <image-viewer :initial-index="currentImageIndex" v-if="showViewer" :on-close="(e) => showViewer = false"
            :url-list="currentImageList">
        </image-viewer>
        <UploadExaminationReport :dialogVisible.sync="isShowReportEditAlert" @onSucceed="onReportEditSucceed"
            :options="alertOption">
        </UploadExaminationReport>
    </div>
</template>

<script>

import { DateTool } from "@js/date-tool.js";
import { isEmpty, ifEmpty } from '@js/empty-tool.js'
import BaseLineInfoCardImg from './baseline-info-card-img.vue'
import { getPathExtension } from '@js/file-tool.js'
export default {
    props: {
        isEdit: Boolean,
        reportData: Object,
        user: Object,
    },
    components: {
        BaseLineInfoCardImg,
        BaselineAlertImage: () => import('@c/user-main/baseline/baseline-info-card/baseline-alert-image.vue'),
        BaselineAlertReport: () => import('@c/user-main/baseline/baseline-info-card/baseline-alert-report.vue'),
        UploadExaminationReport: () => import('@c/user-main/common/upload-examination-report/index.vue'),
        ImageViewer: () => import('@c/common/image-viewer.vue'),
    },
    data() {
        return {
            editData: null,
            isShowImageAlert: false,
            isShowReportEditAlert: false,
            showViewer: false,
            currentImageIndex: 0,
            currentImageList: [],
            alertOption: {
                edit: false,
                type: 'sleep-file',
                data: null
            }
        }
    },
    computed: {
        data() {
            if (this.isEdit) {
                return this.editData
            } else {
                return this.reportData
            }
        }
    },
    created() {
    },
    mounted() {

    },
    watch: {
        reportData: {
            handler(newVal) {
                this.editData = { ...newVal }
            },
            immediate: true
        },
        isEdit: {
            handler(newVal) {
                this.editData = { ...this.reportData }
            },
            immediate: true
        }
    },
    methods: {
        getPathExtension,
        reportParams(item) {
            if (!item) return ''
            const timeStr = isEmpty(item.reportTime) ? '' :
                `日期：${item.reportTime.split(' ')[0]} `;
            const ahiStr = isEmpty(item.ahi) ? '' : `AHI：${item.ahi}次 / 小时 `;
            const ODI3Str = isEmpty(item.odi) ? '' : `ODI3：${item.odi}次 / 小时 `;
            const aiStr = isEmpty(item.ai) ? '' : `AI：${item.ai}次 / 小时 `;
            const hiStr = isEmpty(item.hi) ? '' : `HI：${item.hi}次 / 小时 `;
            const spo2minStr = isEmpty(item.spo2min) ? '' : `最低血氧：${item.spo2min}% `;
            const spo2avgStr = isEmpty(item.spo2avg) ? '' : `平均血氧：${item.spo2avg}%`;
            const str = timeStr + ahiStr + ODI3Str + aiStr + hiStr + spo2minStr + spo2avgStr
            return str == '' ? '无' : str
        },

        onClickEdit(type, item, index) {
            this.alertOption = {
                edit: true,
                type: type,
                index: index,
                data: { ...item }
            }
            this.isShowReportEditAlert = true;
        },

        onClickDelete(type, obj, fileIndex) {
            if (type == 'sleep-file') {
                const index = this.editData.sleepReportFile?.findIndex(item => item.categoryId === obj.categoryId);
                if (index > -1) {
                    this.editData.sleepReportFile[index].file.splice(fileIndex, 1);
                    if (this.editData.sleepReportFile[index].file.length == 0) {
                        this.editData.sleepReportFile.splice(index, 1);
                    }
                }
            } else if (type == 'sleep-database' || type == 'sleep-manual') {
                if (fileIndex > -1) {
                    this.editData.sleepReport.splice(fileIndex, 1);
                }
            } else if (type == 'other-file') {
                const index = this.editData.inspectionReportFile.findIndex(item => item.categoryId === obj.categoryId);
                if (index > -1) {
                    this.editData.inspectionReportFile[index].file.splice(fileIndex, 1);
                    if (this.editData.inspectionReportFile[index].file.length == 0) {
                        this.editData.inspectionReportFile.splice(index, 1);
                    }
                }
            }
            this.$emit('onReportDataChange', { ...this.editData })
        },

        show() {
            this.alertOption = {
                edit: false,
                type: '',
                index: null,
                data: null
            }
            this.isShowReportEditAlert = true;
        },

        hidden() {
            this.isShowReportEditAlert = false;
        },

        onReportEditAlertClose() {
            this.hidden();
        },
        onReportEditSucceed(type, obj) {
            if (Object.keys(this.editData).length == 0) {
                this.editData = {
                    userId: this.user?.id ?? null,
                    hospitalId: this.user?.hospitalId ?? null
                }
            }
            if (type == 'sleep-file') {
                if (!this.editData.sleepReportFile) {
                    this.$set(this.editData, 'sleepReportFile', [])
                }
                const index = this.editData.sleepReportFile.findIndex(item => item.categoryId === obj.categoryId);
                if (index > -1) {
                    if (this.alertOption.edit) {
                        this.editData.sleepReportFile.splice(index, 1, obj);
                    } else {
                        this.editData.sleepReportFile[index].file.push(...obj.file);
                    }
                } else {
                    this.editData.sleepReportFile.push(obj);
                }
            } else if (type == 'sleep-database' || type == 'sleep-manual') {
                if (!this.editData.sleepReport) {
                    this.$set(this.editData, 'sleepReport', [])
                }
                obj.hospitalId = this.user?.hospital_id ?? null
                if (this.alertOption.edit) {
                    this.editData.sleepReport.splice(this.alertOption.index, 1, obj);
                } else {
                    this.editData.sleepReport.push(obj);
                }
                // const index = this.editData.sleepReport.findIndex(item => (item.reportId === obj.reportId && obj.reportId != null));
                // if (index > -1) {
                //     this.editData.sleepReport.splice(index, 1, obj);
                // } else {
                    
                // }
            } else if (type == 'other-file') {
                if (!this.editData.inspectionReportFile) {
                    this.$set(this.editData, 'inspectionReportFile', [])
                }
                const index = this.editData.inspectionReportFile.findIndex(item => item.categoryId === (this.alertOption.edit ? this.alertOption.data?.categoryId : obj.categoryId));
                if (index > -1) {
                    if (this.alertOption.edit) {
                        this.editData.inspectionReportFile.splice(index, 1, obj);
                    } else {
                        this.editData.inspectionReportFile[index].file.push(...obj.file);
                    }
                } else {
                    this.editData.inspectionReportFile.push(obj);
                }
            }
            this.$emit('onReportDataChange', { ...this.editData })
            this.hidden();
        },

        async addFacePic(file, type) {
            try {
                const photoURL = await this.$api.uplodFileRequest(file)
                if (type == 1) {
                    this.editData.frontalPhoto = photoURL
                } else if (type == 2) {
                    this.editData.leftPhoto = photoURL
                } else if (type == 3) {
                    this.editData.rightPhoto = photoURL
                }
                this.$emit('onReportDataChange', { ...this.editData })
            } catch (error) {
                this.$toast.showRed(error)
            }
        },
        deleteFacePic(type) {
            if (type == 1) {
                this.editData.frontalPhoto = null
            } else if (type == 2) {
                this.editData.leftPhoto = null
            } else if (type == 3) {
                this.editData.rightPhoto = null
            }
            this.$emit('onReportDataChange', { ...this.editData })
        },

        onClickReportLink(item) {
            const reportID = item.reportId;
            const reportURL = `https://cloud.bajiesleep.com/report?reportURL=${reportID}`;
            window.open(reportURL, "_blank");
        },

        onClickFileLink(file, index, files) {
            const extension = getPathExtension(file?.url ?? '')
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
            const isImage = imageExtensions.includes(extension)

            if (isImage) {
                const imageList = files.filter(el => {
                    const extension = getPathExtension(el?.url ?? '')
                    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
                    return imageExtensions.includes(extension)
                }).map(el => ({
                    name: el.name,
                    imageUrl: el.url
                }))
                index = imageList.findIndex(el => el.imageUrl == file.url)
                this.showViewer = true
                this.currentImageIndex = index
                this.currentImageList = imageList
            } else {
                window.open(file?.url, "_blank");
            }

        }
    },
}
</script>
<style scoped lang='scss'>
.flex-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.margin-left-10 {
    margin-left: 10px;
}

.report-container {
    padding: 0 25px;
    box-sizing: border-box;
    height: calc(100% - 40px);
    overflow-y: auto;

    .report-section {
        position: relative;

        &-title {
            font-size: 14px;
            color: #000000;
            line-height: 35px;
        }

        &-content {
            margin-left: 15px;
            font-size: 14px;
            color: #000000; //#5889EB;
            line-height: 35px;
        }

        .link {
            display: flex;
            align-items: center;
            height: 35px;
            font-size: 14px;
            font-weight: 500;
            color: #5889EB;
            line-height: 35px;
            // float: right;
            cursor: pointer;

            &-icon {
                width: 17px;
                height: 17px;
                margin-right: 6px;
            }
        }

        .delete-icon {
            width: 12px;
            height: 12px;
            margin-left: 4px;
            color: #8c8c8c;
            align-self: center;
        }

        .edit {
            display: inline-flex;
            align-items: center;
            height: 35px;
            width: 52px;
            font-size: 14px;
            color: #5889EB;
            line-height: 35px;
            margin-left: auto;
            // float: right;

            &-icon {
                width: 15px;
                height: 15px;
                margin-left: 8px;
            }
        }
    }

    .pic-section {
        display: flex;

        &-title {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            line-height: 20px;
        }

        .pic-list {
            margin-left: 17px;
            display: flex;
            flex-wrap: wrap;
        }

        .add-picture {
            line-height: 80px;
            font-size: 25px;
            color: rgba(0, 0, 0, 0.36);
            width: 80px;
            height: 80px;
            background: #F3F3F3;
            border-width: 0;
        }
    }
}

.margin-t-10 {
    margin-top: 10px;
}
</style>