export const getFileSize = (file) => {
    const fileSize = file.size;

    let sizeInBytes = fileSize;
    let sizeUnit = 'bytes';

    if (fileSize >= 1024) {
        sizeInBytes = fileSize / 1024;
        sizeUnit = 'KB';
    }
    if (sizeInBytes >= 1024) {
        sizeInBytes = sizeInBytes / 1024;
        sizeUnit = 'MB';
    }
    if (sizeInBytes >= 1024) {
        sizeInBytes = sizeInBytes / 1024;
        sizeUnit = 'GB';
    }

    return `${sizeInBytes.toFixed(2)} ${sizeUnit}`
}

export const getPathExtension = (path) => {
    const extension = path.replace(/.+\./, "");
    return extension ? extension.toLowerCase() : '';
}

export const getFileExtension = (file) => {
    const filename = file.name
    const extension = filename.replace(/.+\./, "");
    return extension ? extension.toLowerCase() : '';
}

export const getFileName = (file) => {
    const filename = file.name
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1 || lastDotIndex === 0) {
        return filename;
    }
    return filename.substring(0, lastDotIndex);
}

export const downFile = (url, fileName) => {
    const x = new XMLHttpRequest()
    x.open('GET', url, true)
    x.responseType = 'blob'
    x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
    }
    x.send()
}