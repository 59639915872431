<template>
  <div class="pic-container">
    <template v-if="!isEmpty(imageList) && imageList[index]?.imageUrl">
      <div class="pic-box">
        <ElImage class="pic" :src="imageList[index].imageUrl" fit="fill" lazy @click="(e) => showViewer = true"></ElImage>

        <image-viewer :initial-index="index" v-if="showViewer" :on-close="(e) => showViewer = false"
          :url-list="imageList">
        </image-viewer>

        <div v-if="showTitle" class="pic-title">{{ imageList[index].name }}</div>
      </div>
      <div class="mask">
        查看图片
      </div>
      <button @click.stop.prevent="deletePic">
        <img class="delete" src="@imgs/user-main-baseline-delete.png" v-if="isEdit" alt="" />
      </button>
    </template>
    <div v-else class="pic-box">
      <button class="add-picture" @click.stop.prevent="onClickAdd">
        +
      </button>
      <div v-if="showTitle" class="pic-title">{{ imageList[index].name }}</div>
    </div>

    <input ref="fileInput" style="display: none;" type="file" accept="image/*" @change="handleFileSelect">
  </div>
</template>

<script>
import ImageViewer from '@c/common/image-viewer.vue';
import { Image } from 'element-ui'
import { isEmpty } from '@js/empty-tool';
export default {
  props: {
    imageList: {
      type: Array,
      defalut: () => [],
      required: false,
    },
    index: {
      type: Number,
      defalut: 0
    },
    showTitle: {
      type: Boolean,
      defalut: true
    },
    isEdit: Boolean
  },
  components: {
    ElImage: Image,
    ImageViewer
  },
  data() {
    return {
      showViewer: false
    }
  },
  computed: {
    imageUrls() {
      return this.imageList?.map(el => el.imageUrl)
    }
  },
  created() {

  },
  mounted() {
  },
  destroyed() {
    if (this.localFileUrl) {
      URL.revokeObjectURL(this.localFileUrl)
    }
  },
  methods: {
    onClickAdd() {
      this.resetFileInput();
      this.$refs.fileInput.click();
    },
    resetFileInput() {
      const input = this.$refs.fileInput;
      input.value = ''; // 重置当前值
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.$emit('onClickAdd', file)
    },
    deletePic(index) {
      this.$emit('onClickDelete')
      URL.revokeObjectURL(this.localFileUrl);
    },
    isEmpty,
  },
}
</script>
<style scoped lang='scss'>
.pic-container {
  position: relative;
  width: 80px;
  margin-right: 20px;

  .pic-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .pic {
      width: 80px;
      height: 80px;
    }

    .pic-title {
      margin-top: 5px;
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #000000;
      line-height: 17px;
    }


  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 80px;
    font-size: 14px;
    color: transparent;
    text-align: center;
    width: 80px;
    height: 80px;
    background-color: transparent;
    border-width: 0;
    transition: background-color 0.3s ease;
    pointer-events: none;

    // &:hover {
    //     background-color: rgba(0, 0, 0, 0.24);
    //     color: rgb(0, 0, 0);
    // }
  }

  .pic-box:hover+.mask {
    background-color: rgba(0, 0, 0, 0.24);
    color: rgb(0, 0, 0);
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
  }
}

.add-picture {
  line-height: 80px;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.36);
  width: 80px;
  height: 80px;
  background: #F3F3F3;
  border-width: 0;
}
</style>
